<template>
  <div class="landing-section-wrapper">
    <section class="landing-section second-landing-section flex-col">
      <h3 class="section-title">Our aim is to provide you..</h3>

      <div class="content-grid">
        <div class="landing-image flex-col">
          <img src="@/assets/secondry illustration.png" alt="" />
        </div>

        <div class="accordions-container">
          <div class="accordion-head" @click="setActive(1, $event)">
            Best exchanges
          </div>
          <div
            class="accordion-content"
            :class="active == 1 ? 'accordion-active' : ''"
          >
            <p>
              There are several parameters to define which
              <router-link to="exchanges">
                <u><b>exchange</b></u>
              </router-link>
              is the best for your needs - find out which suits you!
            </p>
          </div>

          <div class="accordion-head" @click="setActive(2, $event)">
            Current prices for top currencies
          </div>
          <div
            class="accordion-content"
            :class="active == 2 ? 'accordion-active' : ''"
          >
            <p>
              We provide a platform where
              <router-link to="currencies">
                <u><b>prices</b></u>
              </router-link>
              , percentual movements over time, market caps and other
              information in real time!
            </p>
          </div>

          <div class="accordion-head" @click="setActive(3, $event)">
            The best softwares and wallets on the market
          </div>
          <div
            class="accordion-content"
            :class="active == 3 ? 'accordion-active' : ''"
          >
            <p>
              With a broad experience of different
              <router-link to="software">
                <u><b>software</b></u>
              </router-link>
              we have selected our top favorites and listed them for you! And
              having all that currency in your own possession is important, get
              your
              <router-link to="wallets">
                <u><b>wallet</b></u>
              </router-link>
              today!
            </p>
          </div>

          <button class="btn" @click="modalOpen = true">ASK US ANYTHING</button>
          <ContactModal v-if="modalOpen" @close-modal="modalOpen = false" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactModal from "./ContactModal.vue";

export default {
  name: "SecondSection",
  components: { ContactModal },
  data() {
    return {
      active: 1,
      modalOpen: false,
    };
  },
  methods: {
    setActive(id) {
      if (this.active == id) {
        this.active = 0;
      } else this.active = id;
    },
  },
};
</script>

<style>
</style>