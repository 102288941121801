<template>
  <div class="instructions-icon" v-if="displayIcon">
    <img
      class="home-icon"
      src="@/assets/icons/home.svg"
      alt=""
      @mouseover="popupOpen = true"
      @mouseleave="popupOpen = false"
    />

    <div class="instructions-popup" v-show="popupOpen">
      Don't forget to set CoinShuttle as your homepage for daily prices!
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructionsModal",
  computed: {
    displayIcon() {
      if (this.$route.name == "exchanges" || this.$route.name == "currencies")
        return true;
      return false;
    },
  },
  methods: {
    closeForm() {},
  },
  data() {
    return {
      popupOpen: false,
    };
  },
};
</script>

<style>
</style>