<template>
  <div class="landing-section-wrapper top-cryptos-wrapper">
    <div class="landing-section top-cryptos">
      <!-- <div class="top5-title">
        <h3>TOP COINS</h3>
        <router-link to="exchanges">See all</router-link>
      </div> -->

      <div class="top5-wrapper">
        <div class="coins-wrapper">
          <div
            v-for="(currency, i) in currencies"
            :key="i"
            class="top5-coin"
            @click="goToCurrencies"
          >
            <img :src="currency.image_url" alt="" />
            <div class="name">
              <b>{{ currency.name }}</b>
            </div>
            <div class="price">
              {{ currency.price_usd }}
            </div>
          </div>
        </div>

        <router-link to="currencies">
          <b>SEE ALL</b>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatMoney } from "@/js/helpers.js";

export default {
  name: "top-cryptos",
  data() {
    return {
      currencies: [],
    };
  },
  created() {
    axios
      .get(process.env.VUE_APP_API_URL + "/coins-top5")
      .then((res) => {
        let formattedResponse = res.data.map((val) => {
          val.price_usd = formatMoney(val.price_usd);
          return val;
        });
        this.currencies = formattedResponse;
      })
      .catch(() => {
        this.currencies = [];
      });
  },
  methods: {
    goToCurrencies() {
      this.$router.push({ name: "currencies" });
    },
  },
};
</script>

<style>
</style>