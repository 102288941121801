import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../pages/landing.vue";
import Currencies from "../pages/currencies.vue";
import Exchanges from "../pages/exchanges.vue";
import Software from "../pages/software.vue";
import Wallets from "../pages/wallets.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
    meta: {
      title: "Coin Shuttle",
    },
  },
  {
    path: "/currencies",
    name: "currencies",
    component: Currencies,
    meta: {
      title: "Currencies | Coin Shuttle",
    },
  },
  {
    path: "/exchanges",
    name: "exchanges",
    component: Exchanges,
    meta: {
      title: "Exchanges | Coin Shuttle",
    },
  },
  {
    path: "/software",
    name: "software",
    component: Software,
    meta: {
      title: "Software | Coin Shuttle",
    },
  },
  {
    path: "/wallets",
    name: "wallets",
    component: Wallets,
    meta: {
      title: "Wallets | Coin Shuttle",
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
