<template>
  <div class="modal">
    <div class="modal-content contact-modal">
      <div class="close-btn" @click="closeForm">&#10006;</div>
      <div class="modal-title">Email our support</div>
      <form @submit="sendEmail">
        <input type="text" placeholder="Name" v-model="name" required />
        <input type="email" placeholder="E-mail" v-model="email" required />
        <input type="text" placeholder="Subject" v-model="subject" required />
        <textarea placeholder="Message" v-model="message" rows="5" required />
        <input
          class="submit-contact-btn"
          type="submit"
          value="Send"
          v-if="!loading"
        />
        <div class="loading-spinner" v-else></div>

        <p class="contact-error" v-if="connectionError">
          Something went wrong while sending the email. Please try again later.
        </p>
        <p class="contact-error" v-if="formatError">
          Please check that all fields are filled and email is valid.
        </p>
        <p class="contact-success" v-if="success">
          Email sent successfully, our team will get back to you as soon as
          possible.
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactModal",
  data() {
    return {
      loading: false,
      name: "",
      email: "",
      subject: "",
      message: "",
      success: false,
      formatError: false,
      connectionError: false,
    };
  },
  methods: {
    async sendEmail(e) {
      e.preventDefault();
      this.formatError = false;
      this.connectionError = false;
      this.loading = true;
      try {
        await axios.post(process.env.VUE_APP_API_URL + "/email-support", {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message,
        });
        this.success = true;
      } catch (e) {
        if (e.response.status == 422) this.formatError = true;
        else this.connectionError = true;
      } finally {
        this.loading = false;
      }
    },
    closeForm() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style>
</style>