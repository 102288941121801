<template>
  <nav class="navbar" :class="currentRoute != 'landing' ? 'navbar-bg' : ''">
    <router-link to="/" class="nav-logo">
      <img
        v-if="currentRoute == 'landing'"
        src="@/assets/logos/coinshuttle-logo-colored.png"
        alt=""
      />
      <img v-else src="@/assets/logos/coinshuttle-logo-white.png" alt="" />
    </router-link>

    <div class="navbar-links">
      <ul class="desktop-nav">
        <li><router-link to="currencies">Currencies</router-link></li>
        <li><router-link to="exchanges">Exchanges</router-link></li>
        <li><router-link to="wallets">Wallets</router-link></li>
        <li><router-link to="software">Software</router-link></li>
      </ul>

      <div class="mobile-nav">
        <div class="menu-icon">
          <img src="@/assets/menu.svg" alt="" />
        </div>

        <div class="dropdown">
          <li><router-link to="currencies">Currencies</router-link></li>
          <li><router-link to="exchanges">Exchanges</router-link></li>
          <li><router-link to="wallets">Wallets</router-link></li>
          <li><router-link to="software">Software</router-link></li>
        </div>
      </div>

      <InstructionsModal />
    </div>

    <div class="mobile-navbar"></div>
  </nav>
</template>

<script>
import InstructionsModal from "@/components/InstructionsModal.vue";

export default {
  name: "Navbar",
  components: { InstructionsModal },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>
