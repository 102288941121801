const formatCoin = (coin) => {
  if (coin.image_url)
    coin.name = `<div class="table-avatar-wrapper"><img src="${coin.image_url}" /> ${coin.name}</div>`;

  coin.price_usd = formatMoney(coin.price_usd);
  coin.high_24h = formatMoney(coin.high_24h);
  coin.low_24h = formatMoney(coin.low_24h);
  coin.all_time_high = formatMoney(coin.all_time_high);
  coin.market_cap = formatMoney(coin.market_cap);
  coin.link = formatLink(coin.link, coin.affiliate_link, "Visit");
  coin.circulating = formatCirculating(coin.circulating);
  coin.total_supply = formatCirculating(coin.total_supply);
  coin.change_24h = formatChange(coin.change_24h);
  coin.marketcap_dom_pct = formatMarketCap(coin.marketcap_dom_pct);
  return coin;
};

const formatExchange = (exchange) => {
  if (exchange.image_url)
    exchange.name = `<div class="table-avatar-wrapper"><img src="${exchange.image_url}" /> ${exchange.name}</div>`;
  exchange.link = formatLink(exchange.link, exchange.affiliate_link, "Trade");
  exchange.trade_volume_24h_btc = formatExchangeVolume(
    exchange.trade_volume_24h_btc
  );
  return exchange;
};

const formatCirculating = (val) => {
  if (!val) return "0";
  let integerPart = val.split(".")[0];
  integerPart = addSpaceBetnNumbers(integerPart);

  return integerPart;
};

const formatMoney = (val) => {
  if (!val) return "";
  let decimalPart = val.split(".")[1];
  let integerPart = val.split(".")[0];
  integerPart = addSpaceBetnNumbers(integerPart);
  if (decimalPart) {
    decimalPart = decimalPart.slice(0, 2);
  } else {
    return `$ ${integerPart}`;
  }

  if (`$ ${integerPart}.${decimalPart}` == "$ 0.00") return "$ 0.01";

  return `$ ${integerPart}.${decimalPart}`;
};

const formatLink = (link, affiliateLink, linkTxt) => {
  if (!link) return "";
  if (affiliateLink != null && affiliateLink != "")
    return `<a class="table-link" target="_blank" href="${affiliateLink}">${linkTxt}</a>`;
  return `<a class="table-link" target="_blank" href="${link}">${linkTxt}</a>`;
};

const formatChange = (val) => {
  if (!val) return "";
  let decimalPart = val.split(".")[1];
  decimalPart = decimalPart.slice(0, 2);
  let integerPart = val.split(".")[0];
  let formattedChange = integerPart + "." + decimalPart + " %";

  if (formattedChange[0] == "-")
    return `<div style="color:red;">${formattedChange}</div>`;
  else return `<div style="color:green;">${formattedChange}</div>`;

  //return formattedChange;
};

const formatMarketCap = (val) => {
  if (!val) return "";
  let decimalPart = val.split(".")[1];
  let integerPart = val.split(".")[0];
  decimalPart = decimalPart.slice(0, 2);
  return `${integerPart}.${decimalPart} %`;
};

const addSpaceBetnNumbers = (number) => {
  let formattedNumber = "";
  let spaceCounter = 0;

  for (let i = number.length - 1; i >= 0; i--) {
    formattedNumber += number[i];
    spaceCounter++;
    if (spaceCounter == 3) {
      formattedNumber += " ";
      spaceCounter = 0;
    }
  }

  let reversedNumber = "";
  for (let i = formattedNumber.length - 1; i >= 0; i--) {
    reversedNumber += formattedNumber[i];
  }

  return reversedNumber;
};

const formatExchangeVolume = (val) => {
  if (!val) return "0";
  let integerPart = addSpaceBetnNumbers(val.split(".")[0]);
  return `${integerPart} BTC`;
};

export { formatCoin, formatExchange, formatMoney };
