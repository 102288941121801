<template>
  <div class="landing-section-wrapper">
    <div class="landing-section first-landing-section">
      <div class="flex-col content">
        <div class="">
          <h1>
            The hub for all you need to plan and handle your crypto investments
          </h1>

          <div class="responsive-section-image">
            <img src="@/assets/main illustration.png" alt="" />
          </div>
        </div>

        <p>
          We'll provide daily content on our platform regarding current prices,
          available exchanges and software updates. Our aim is to always present
          the best alternatives for you. And it doesn't matter if you are a new
          or experienced trader/investor, we will provide relevant material for
          all skill levels!
        </p>

        <small>Subscribe to recieve special offers </small><br />

        <div class="flex-row landing-input">
          <input
            type="text"
            name=""
            id=""
            placeholder="Enter email"
            v-model="email"
          />
          <button class="btn" @click="subscribeToList">GO</button>
        </div>
        <small v-if="success">Subscribed ! Stay Tuned!</small>
        <small v-if="error"
          >Please check that email is valid! If email is valid please try again
          later</small
        >
        <!-- <div class="flex-row section-btns">
          <button class="btn">WALLET</button>
          <button class="btn">QUICK SWAP</button>
        </div> -->
      </div>
      <div class="landing-image">
        <img src="@/assets/main illustration.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FirstSection",
  data() {
    return {
      error: false,
      success: false,
      email: "",
    };
  },
  methods: {
    subscribeToList() {
      axios
        .post(process.env.VUE_APP_API_URL + "/subscribe", {
          email: this.email,
        })
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>

<style>
</style>