<template>
  <div class="landing-section-wrapper">
    <section class="landing-section third-landing-section flex-col">
      <h3 class="section-title">We are here to support you in your journey</h3>
      <div class="container-grid">
        <div class="flex-col card">
          <img src="@/assets/invest.png" alt="" />
          <h4 class="card-title">Invest</h4>
          <p>
            Everyone has to start somewhere, but to get the most out of the
            initial investment sets the foundation for your upcoming investor or
            trader careers!
          </p>
        </div>

        <div class="flex-col card">
          <img src="@/assets/safe storing.png" alt="" />
          <h4 class="card-title">Trade</h4>
          <p>
            Blockchain is an industry that is growing rapidly and taking
            opportunities
          </p>
        </div>

        <div class="flex-col card">
          <img src="@/assets/trade.png" alt="" />
          <h4 class="card-title">Safe storing</h4>
          <p>
            Keeping your coins insecurely is more common than one may think and
            it can so easily be changed to the better!
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ThirdSection",
};
</script>

<style>
</style>