<template>
  <div class="chart-page">
    <h1 class="chart-title">SOFTWARE WE RECOMMEND</h1>
    <Listing v-for="(s, i) in software" :key="i" :data="s" />
  </div>
</template>

<script>
import Listing from "@/components/charts/Listing.vue";

export default {
  name: "Software",
  components: { Listing },
  data() {
    return {
      software: [
        {
          img: "software/coinrule-logo.png",
          text:
            "As a bit of a newcomer Coinrule has made ground and supported by big exchanges it performs well with cross exchange trading and much more! With a wast portfolio of existing trading strategies users can try out any of them and modify them just as it suits them! A perfect tool if you are interested in learning but still need some guidance in the beginning! ",
          link: "http://coinrule.io/",
        },
        {
          img: "software/cryptohopper-logo.png",
          text:
            "Cryptohopper is one of the most known platforms out there for personal trading. We have been using Cryptohopper and really appreciated the wide scope of features along with its very nicely design interface. It does not matter if you are a beginner to try your first trades or an experienced trader - Cryptohopper is suitable for everyone!",
          link: "http://cryptohopper.com/",
        },
        {
          img: "software/trality-logo.png",
          text:
            "Finally we get to our favorite this far - Trality! It really does not matter if you are a Python guru or a trader, Trality allows full usability either by using codes or rules to define your strategies. The interface is not as clean as Coinrule or Cryptohopper, but in the end we're looking for profits, not tidy interfaces ",
          link: "https://www.trality.com/",
        },
      ],
    };
  },
};
</script>

<style>
</style>