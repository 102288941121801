<template>
  <div class="main-content">
    <TopCryptos />
    <FirstSection />
    <SecondSection />
    <ExchangesSection />
    <ThirdSection />
  </div>
</template>

<script>
import FirstSection from "@/components/landing/FirstSection.vue";
import SecondSection from "@/components/landing/SecondSection.vue";
import ThirdSection from "@/components/landing/ThirdSection.vue";
import ExchangesSection from "@/components/landing/ExchangesSection.vue";
import TopCryptos from "@/components/landing/TopCryptos.vue";

export default {
  name: "landing",
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    ExchangesSection,
    TopCryptos,
  },
};
</script>