<template>
  <div class="detail-collapsible">
    <div class="coin-info-grid">
      <div class="collapsible-item">
        <h4 class="title">Market cap</h4>
        <p>{{ rowData.market_cap }}</p>
      </div>

      <div class="collapsible-item">
        <h4 class="title">High last 24h</h4>
        <p>{{ rowData.high_24h }}</p>
      </div>

      <div class="collapsible-item">
        <h4 class="title">Low last 24h</h4>
        <p>{{ rowData.low_24h }}</p>
      </div>

      <div class="collapsible-item">
        <h4 class="title">All time high</h4>
        <p>{{ rowData.all_time_high }}</p>
      </div>

      <div class="collapsible-item">
        <h4 class="title">Reached ath at</h4>
        <p>{{ rowData.reached_high_at.split("T")[0] }}</p>
      </div>
    </div>

    <div class="chart-container">
      <!-- TradingView Widget BEGIN -->
      <div class="tradingview-widget-container">
        <div :id="`tradingview_${id}`"></div>
        <div class="tradingview-widget-copyright">
          <a
            :href="`https://www.tradingview.com/symbols/${rowData.slug}USD/`"
            rel="noopener"
            target="_blank"
            ><span class="blue-text">{{ rowData.slug }}USD Chart</span></a
          >
          by TradingView
        </div>
        <!-- <script
          type="application/javascript"
          src="https://s3.tradingview.com/tv.js"
        ></script> -->
        <script type="application/javascript">
          new TradingView.widget({
            width: "100%",
            height: "100%",
            symbol: "{{rowData.slug}}USD",
            interval: "D",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            container_id: "tradingview_{{id}}",
          });
        </script>
      </div>
      <!-- TradingView Widget END -->
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrencyDetails",
  created() {
    this.id = Math.random().toString(36).substring(7);
  },
  data() {
    return {
      id: "",
    };
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    options: {
      type: Object,
    },
  },
};
</script>

<style>
</style>