<template>
  <div id="app">
    <div id="content-wrap">
      <Navbar />
      <router-view />
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { Navbar, Footer },
  mounted() {
    let tradingViewScript = document.createElement("script");
    tradingViewScript.setAttribute("src", "https://s3.tradingview.com/tv.js");
    document.head.appendChild(tradingViewScript);
  },
  created() {
    document.title = this.$route.meta.title;
  },
};
</script>

<style lang="scss">
@import "@/css/style.scss";
</style>
