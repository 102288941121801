<template>
  <footer class="footer">
    <div class="website-info">
      <img
        class="footer-logo"
        src="@/assets/logos/coinshuttle-logo-colored.png"
        alt=""
      />
      <div class="description-block"><i>The shuttle toward the moon!</i></div>

      <div class="social-icons-wrapper">
        <a
          href="https://www.instagram.com/newshub.crypto"
          target="_blank"
          class="social-icon"
        >
          <img src="@/assets/icons/instagram.svg" alt="" />
        </a>
      </div>
    </div>

    <div>
      <div class="title">FAQ</div>
      <div class="description-block">
        Email Support: coinshuttlex@gmail.com<br />
      </div>
    </div>

    <div>
      <div class="title">Donations are appreciated!</div>
      <div class="description-block">
        ETH: 0xf5d0d2bc57a0afdc01d6a19f1e9d6e4164470f9a <br />
        BTC: 1P38kswqReUCgX9rRzRVboehAB9Agbtgqs
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>