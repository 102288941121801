<template>
  <div class="chart-page">
    <h1 class="chart-title">TOP 100 CRYPTOCURRENCIES LIVE CHART</h1>
    <div class="loading-spinner" v-if="loading"></div>
    <div v-else-if="error">An error has occured</div>
    <vuetable
      v-else
      ref="vuetable"
      :fields="fields"
      :api-mode="false"
      row-class="table-row"
      :data="currencies"
      :detail-row-component="extraRow"
      track-by="rank"
      @vuetable:row-clicked="openDetailRow"
    ></vuetable>
  </div>
</template>

<script>
import CurrencyDetails from "@/components/charts/CurrencyDetails.vue";
import Vuetable from "vuetable-2";
import axios from "axios";
import { formatCoin } from "@/js/helpers.js";

export default {
  name: "Currencies",
  components: { Vuetable },
  methods: {
    openDetailRow(e) {
      this.$refs.vuetable.toggleDetailRow(e.rank);
    },
  },
  created() {
    axios
      .get(process.env.VUE_APP_API_URL + "/coins")
      .then((res) => {
        let formattedResponse = res.data.map((val) => {
          return formatCoin(val);
        });
        this.currencies = formattedResponse;
        this.loading = false;
      })
      .catch(() => {
        this.currencies = [];
        this.error = true;
      });
  },
  data() {
    return {
      currencies: [],
      loading: true,
      error: false,
      extraRow: CurrencyDetails,
      fields: [
        {
          name: "rank",
          title: "",
          titleClass: "table-center table-responsive",
          dataClass: "table-center table-responsive",
          // width: "10%",
        },
        {
          name: "name",
          titleClass: "table-left",
          dataClass: "table-left",
          // width: "30%",
        },
        {
          name: "price_usd",
          title: "Price ($)",
          titleClass: "table-right table-no-break",
          dataClass: "table-right table-no-break",
          // width: "26%",
        },
        {
          name: "change_24h",
          title: "24h Change",
          titleClass: "table-right table-responsive",
          dataClass: "table-right  table-responsive",
          // width: "10%",
        },
        {
          name: "total_supply",
          title: "Total Supply",
          titleClass: "table-right table-responsive",
          dataClass: "table-right table-responsive",
          // width: "10%",
        },
        {
          name: "circulating",
          titleClass: "table-right  table-responsive",
          dataClass: "table-right  table-responsive",
          // width: "10%",
        },
        {
          name: "link",
          title: "",
          titleClass: "table-center",
          dataClass: "table-center",
          // width: "10%",
        },
      ],
    };
  },
};
</script>

<style>
</style>