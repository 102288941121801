<template>
  <div class="chart-page">
    <h1 class="chart-title">TOP 100 EXCHANGES</h1>
    <div class="loading-spinner" v-if="loading"></div>
    <div v-else-if="error">An error has occured</div>
    <vuetable
      v-else
      ref="vuetable"
      :fields="fields"
      :api-mode="false"
      row-class="table-row"
      :data="exchanges"
    ></vuetable>
  </div>
</template>

<script>
import Vuetable from "vuetable-2";
import axios from "axios";
import { formatExchange } from "@/js/helpers.js";

export default {
  name: "Exchanges",
  components: { Vuetable },
  created() {
    axios
      .get(process.env.VUE_APP_API_URL + "/exchanges")
      .then((res) => {
        let formattedResponse = res.data.map((val) => {
          return formatExchange(val);
        });
        this.exchanges = formattedResponse;
        this.loading = false;
      })
      .catch(() => {
        this.exchanges = [];
        this.error = true;
      });
  },
  data() {
    return {
      exchanges: [],
      loading: true,
      error: false,
      fields: [
        {
          name: "trust_score_rank",
          title: "",
          titleClass: "table-center table-responsive",
          dataClass: "table-center table-responsive",
          width: "5%",
        },
        {
          name: "name",
          titleClass: "table-left",
          dataClass: "table-left",
          width: "30%",
        },
        {
          name: "trade_volume_24h_btc",
          title: "Volume (24H) (BTC)",
          titleClass: "table-right table-responsive table-no-break",
          dataClass: "table-right table-responsive",
          width: "15%",
        },
        {
          name: "country",
          title: "Country",
          titleClass: "table-left table-responsive",
          dataClass: "table-left  table-responsive",
          width: "20%",
        },
        {
          name: "link",
          title: "",
          titleClass: "table-center",
          dataClass: "table-center",
          width: "10%",
        },
      ],
    };
  },
};
</script>

<style>
</style>