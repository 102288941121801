<template>
  <div class="landing-section-wrapper">
    <div class="landing-section exchanges-section flex-col">
      <h2 class="title">Trade on your <b>FAVORITE EXCHANGES</b></h2>
      <p class="desc">
        Recommended exchanges that provide high efficiency trading with high
        security and some of the lowest fees in the industry
      </p>
      <div class="exchanges-grid">
        <a
          href="https://www.binance.com/en"
          class="exchange-card"
          target="_blank"
        >
          <div class="card-img">
            <img src="@/assets/logos/binance.png" alt="" />
          </div>
          <div class="card-txt">BINANCE</div>
        </a>

        <a
          href="https://www.coinbase.com/"
          class="exchange-card"
          target="_blank"
        >
          <div class="card-img">
            <img src="@/assets/logos/coinbase.png" alt="" />
          </div>
        </a>
        <a href="https://hitbtc.com/" class="exchange-card" target="_blank">
          <div class="card-img">
            <img src="@/assets/logos/hitbtc.jpg" alt="" />
          </div>
          <div class="card-txt">HITBTC</div>
        </a>
        <a href="https://www.kraken.com/" class="exchange-card" target="_blank">
          <div class="card-img">
            <img src="@/assets/logos/kraken.png" alt="" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExchangeSection",
};
</script>

<style>
</style>