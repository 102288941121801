<template>
  <div class="listing">
    <div class="listing-img">
      <img :src="getImgUrl(data.img)" alt="" />
    </div>
    <p class="listing-text">
      {{ data.text }}
    </p>
    <div class="listing-link">
      <a :href="data.link" target="_blank">Visit</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Listing",
  props: { data: Object },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/logos/" + pic);
    },
  },
};
</script>

<style>
</style>