<template>
  <div class="chart-page">
    <h1 class="chart-title">WALLETS WE RECOMMEND</h1>
    <Listing v-for="(w, i) in wallets" :key="i" :data="w" />
  </div>
</template>

<script>
import Listing from "@/components/charts/Listing.vue";

export default {
  name: "Wallets",
  components: { Listing },
  data() {
    return {
      wallets: [
        {
          img: "wallets/ledger-nanos.png",
          text:
            "The Ledger Nano S is a popular cryptocurrency hardware wallet that supports a wide variety of coins. The Ledger Nano S gives users high security for their coins, via an easy to use interface.",
          link: "https://shop.ledger.com/?r=1a066c391aa4",
        },
        {
          img: "wallets/ledger-nanox.png",
          text:
            "The Ledger Nano X is one step ahead of other hardware wallets on the market today. The increased capacity for coins, combined with its bluetooth connectivity makes it a powerhouse for managing crypto assets.",
          link: "https://shop.ledger.com/?r=1a066c391aa4",
        },
        {
          img: "wallets/trezor-one.png",
          text:
            "TREZOR is a Bitcoin hardware wallet and launched in August 2014. It was the first Bitcoin hardware wallet, offering secure cold storage plus the ability to spend with the convenience of a hot wallet. It is a small, key-sized device which connects to your computer with a USB cable. It stores your Bitcoin and cryptocurrency private keys offline and signs transactions.",
          link: "https://shop.trezor.io/?offer_id=10&aff_id=7032",
        },

        {
          img: "wallets/trezor-modelt.jpg",
          text:
            "The Trezor Model T is one of the most expensive hardware wallets available, but makes up for its price with premium features, universal support and ease of use.",
          link: "https://shop.trezor.io/?offer_id=10&aff_id=7032",
        },
      ],
    };
  },
};
</script>

<style>
</style>